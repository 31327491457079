import React, { createContext, useState } from 'react';
export const ProfileContext = createContext();

export const UseContext = ({ children }) => {
    const [viewFilter, setviewFilter] = useState();
    const [limitValue, setlimitValue] = useState();
    const [minTargetCPC, setminTargetCPC] = useState();
    const [maxTargetCPC, setmaxTargetCPC] = useState();
    const [orderSwitch, setOrderSwitch] = useState(false);
    const [orderVal, setOrderVal] = useState();
    const sideFilterShow = {
        campaignFilter: false,
        customFilter: false
    }

    let data = {
        viewFilter,
        setviewFilter,
        sideFilterShow,
        minTargetCPC,
        setminTargetCPC,
        maxTargetCPC,
        setmaxTargetCPC,
        limitValue,
        setlimitValue,
        orderSwitch,
        setOrderSwitch,
        orderVal,
        setOrderVal
    }

    return (
        <ProfileContext.Provider value={data}>
            {children}
        </ProfileContext.Provider>
    )
}