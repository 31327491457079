export const conditionOperators = [
    {
        key: "GREATER_THAN",
        value: ">",
    },
    {
        key: "LESS_THAN_OR_EQUAL_TO",
        value: "<=",
    },
    {
        key: "GREATER_THAN_OR_EQUAL_TO",
        value: ">=",
    },
    {
        key: "LESS_THAN",
        value: "<",
    },
    {
        key: "EQUAL_TO",
        value: "==",
    },
]

export const serachLabel = [
    { value: "Placements", label: "Placements" },
    { value: "Campaigns", label: "Campaigns" },
    { value: "AnyPlacements", label: "Any Placements" },
];

export const conditionType = [
    { value: "Spend", label: "Spend" },
    { value: "Sales", label: "Sales" },
    { value: "ACOS", label: "ACOS" },
    { value: "ROAS", label: "ROAS" },
    { value: "Impressions", label: "Impressions" },
    { value: "Clicks", label: "Clicks" },
    { value: "Units", label: "Units" },
    { value: "CTR", label: "CTR" },
    { value: "CPC", label: "CPC" },
    { value: "CVR", label: "CVR" },
    { value: "Orders", label: "Orders" },
]

export const sortList = [
    { value: "ASCE", label: "Ascending" },
    { value: "DESC", label: "Descending" }
]

export const validatorLabel = [
    { value: "LESS_THAN", label: "less than" },
    { value: "LESS_THAN_OR_EQUAL_TO", label: "less than or equal to" },
    { value: "GREATER_THAN_OR_EQUAL_TO", label: "greater than or equal to" },
    { value: "GREATER_THAN", label: "greater than" },
    { value: "EQUAL_TO", label: "equal to" },
];

export const sbCampaignFilterData = [
    { label: 'All', value: '' },
    { label: 'Exact', value: 'Exact' },
    { label: 'Phrase', value: 'Phrase' },
    { label: 'Broad', value: 'Broad' },
    { label: 'ASIN', value: 'ASIN' },
    { label: 'Ranking', value: 'Ranking' },
]

export const sdCampaignFilterData = [
    { label: 'All', value: '' },
    { label: 'ASIN', value: 'ASIN' },
]

export const spCampaignFilterData = [
    { label: 'All', value: '' },
    { label: 'Ranking', value: 'Ranking' },
    { label: 'Exact', value: 'Exact' },
    { label: 'Phrase', value: 'Phrase' },
    { label: 'Broad', value: 'Broad' },
    { label: 'Auto', value: 'Auto' },
    { label: 'ASIN', value: 'ASIN' },
    { label: 'All non ranking', value: 'All non ranking' },
]
