import React, { useState } from 'react';
import Sidebar from '../layout/component/sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import Loader from '../helper/loader/loader';
import { useSelector } from 'react-redux';

const DefaultLayout = () => {
    const [open, setOpen] = useState(true);
    const Loading = useSelector(state => state.data)

    return (
        <div id='wrapper' className={`${open ? 'toggled' : ''}`}>
            {
                Loading.loader === true && <Loader />
            }
            <Sidebar open={open} setOpen={setOpen} />
            <div className='outlet_wrapper'>
                <Outlet />
            </div>
        </div>
    )
}

export default DefaultLayout