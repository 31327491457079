import React from 'react'
import Filter from '../../../../helper/filter/Filter';
import { useState } from 'react';
import { ApiAdjustStatus, ApiCreateLimitValue, ApiCreateTargetCPC, ApiDeleteAverageAOV, ApiGetAverageAOV, ApiGetTargetCPC, ApiLimitSave, ApiTopModifier } from '../../../../api-wrapper/setting/ApiSettings';
import Toast from '../../../../helper/toast/Toast';
import { ProfileContext } from '../../../../usecontext/useContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import AOVModal from './AovModal';

const Other = () => {
  const { minTargetCPC, setminTargetCPC, maxTargetCPC, setmaxTargetCPC, limitValue, setlimitValue } = useContext(ProfileContext);
  const [addModalFlag, setaddModalFlag] = useState(false)
  const [editId, setEditId] = useState({})
  const [error, seterror] = useState(false);
  const [flag, setflag] = useState(false);
  const [getAOV, setgetAOV] = useState([]);
  const [getAOVlist, setgetAOVlist] = useState(false)
  const [TOSPercentage, setTOSPercentage] = useState('');
  const [topError, settopError] = useState('');
  const [adjustStatus, setAdjustStatus] = useState(false)
  const [addAov, setAddAov] = useState(false);
  const [limitArr, setLimitArr] = useState([]);

  const setTargetCPCHandler = () => {
    if (minTargetCPC && maxTargetCPC) {
      ApiCall()
    }
    else {
      seterror(true)
    }
  }
  useEffect(() => {
    getTargetCPC()
    getAOVDATA()
  }, [])

  useEffect(() => {
    if (getAOVlist == true) {
      getAOVDATA()
      setgetAOVlist(false)
    }
  }, [getAOVlist])



  const getTargetCPC = async () => {
    const profileId = localStorage.getItem("profileId") || "";
    await ApiGetTargetCPC({ profileId: profileId })
      .then((e) => {
        if (e?.isSuccess) {
          setminTargetCPC(e.data.minTargetCPC)
          setmaxTargetCPC(e.data.maxTargetCPC)
          setlimitValue(e.data.limitValue)
          setTOSPercentage(e.data.TOSPercentage)
          setAdjustStatus(e.data.excludeAdjustedCampaign)
          setLimitArr(e.limitedCPCData?.conditions)
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Somthing went wrong");
      });
  }



  const getAOVDATA = async () => {
    const profileId = localStorage.getItem("profileId") || "";
    await ApiGetAverageAOV({ profileId: profileId })
      .then((e) => {
        if (e?.isSuccess) {
          setgetAOV(e?.data)
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Somthing went wrong");
      });
  }

  const ApiCall = async () => {
    const profileId = localStorage.getItem("profileId") || "";
    if (minTargetCPC && maxTargetCPC) {
      const data = {
        profileId: profileId,
        minTargetCPC: minTargetCPC,
        maxTargetCPC: maxTargetCPC
      }
      await ApiCreateTargetCPC(data)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Somthing went wrong");
        });
    }
  }

  const deleteAOV = async (id) => {
    await ApiDeleteAverageAOV(id)
      .then((e) => {
        if (e?.isSuccess) {
          Toast.success(e?.message);
          setgetAOVlist(true)
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Somthing went wrong");
      });
  }

  const columns = [
    {
      name: 'Action',
      width: 'auto',
      cell: (e) => {
        return (
          <div className="">
            <i
              className="fa fa-pencil me-1"
              onClick={() => {
                setEditId(e);
                setflag(true)
                setaddModalFlag(true);
                setAddAov(false)
              }}
            ></i>
            <i
              className="fa fa-trash ms-2"
              style={{ color: 'red' }}
              onClick={() => {
                deleteAOV(e._id)
              }}
            ></i>
          </div>
        )
      }
    },
    {
      name: 'Campaign Name',
      selector: (row) => row.campaignName,
      cell: (e) => {
        return (
          <div className='text-uppercase'>{e.campaignName}</div>
        )
      }
    },
    {
      name: 'AOV',
      selector: (row) => row.value,
      cell: (e) => {
        return `$${e.value}`
      }
    },
    {
      name: 'COGS',
      selector: (row) => `$${row.COGS}`,
    }
  ]

  const LimitValueHandler = () => {
    if (limitValue) {
      ApiLimitValue()
    }
  }

  const ApiLimitValue = async () => {
    const profileId = localStorage.getItem("profileId") || "";
    if (limitValue) {
      const data = {
        profileId: profileId,
        limitValue: limitValue,
      }
      await ApiCreateLimitValue(data)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Somthing went wrong");
        });
    }
  }

  const HandleModifier = () => {
    const profileId = localStorage.getItem("profileId") || "";
    if (TOSPercentage == '') {
      settopError('Top Placement Modifier is Required')
    }
    else {
      let data = {
        profileId: profileId,
        TOSPercentage: TOSPercentage
      }
      ApiTopModifier(data)
        .then((res) => {
          if (res?.isSuccess) {
            Toast.success(res?.message);
          } else {
            Toast.error(res?.message);
          }
        }).catch((err) => {
          Toast.error("Somthing went wrong");
        });
    }
  }

  const HandlePercantage = (e) => {
    setTOSPercentage(e.target.value);
    settopError('')
  }

  const statusHandler = () => {
    const profileId = localStorage.getItem("profileId") || "";
    let data = {
      excludeAdjustedCampaign: adjustStatus,
      profileId: profileId
    };
    ApiAdjustStatus(data)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);

        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong");
      });
  }

  const changeHandler = (e, el, name) => {

    el[name] = Number(e.target.value);
    setLimitArr([...limitArr]);
  }

  const limitSaveHandler = () => {
    const profileId = localStorage.getItem("profileId") || "";
    let data = {
      profileId: profileId,
      conditions: limitArr
    }

    ApiLimitSave(data)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);

        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong");
      });
  }
  return (
    <>
      <div className='bg-white'>
        <Filter />
      </div>
      <div className='middle_container addfilter_container'>
        <div className='data_content data_content_btn'>
          <div className='data_modal_btn'>
            <h5>Other Filter</h5>
          </div>
          <div className=' py-4 px-3 setting-div'>
            <div className='setting_inner row row-cols-1 row-cols-md-3 py-2 mb-3'>
              <div className='col col-12 col-md-2 col-lg-2 col-xl-1 heading'>
                <h5>Target CPC</h5>
              </div>
              <div className='col col-12 col-md-4 col-lg-3 col-xl-2'>
                <label>Min-Value :</label>
                <input className='input' type='number' name='minValue' value={minTargetCPC} onChange={(e) => setminTargetCPC(e.target.value)}></input>
                {
                  error && !minTargetCPC &&
                  (
                    <p className='error'>Min-target CPC value is required</p>
                  )
                }
              </div>
              <div className='col col-12 col-md-4 col-lg-3 col-xl-2'>
                <label>Max-Value:</label>
                <input className='input' type='number' name='maxValue' value={maxTargetCPC} onChange={(e) => setmaxTargetCPC(e.target.value)}></input>
                {
                  error && !maxTargetCPC &&
                  (
                    <p className='error'>Max-target CPC value is required</p>
                  )
                }
              </div>
              <div className='col col-12 col-md-1 col-lg-1 text-center'>
                <button className='buttons' onClick={() => setTargetCPCHandler()}>Save</button>
              </div>
            </div>
            <div className='setting_inner row row-cols-1 row-cols-md-3 py-2 mb-3'>
              <div className='col col-12 col-md-2 col-lg-2 col-xl-1 heading'>
                <h5>Top Placement Modifier</h5>
              </div>
              <div className='col col-12 col-md-4 col-lg-3 col-xl-2'>
                <label className='me-4'>Modifier : </label>
                <input className='input ms-2' type='number' name='TOSPercentage' value={TOSPercentage} onChange={(e) => HandlePercantage(e)} ></input>
                {
                  topError &&
                  (
                    <p className='error'>{topError}</p>
                  )
                }
              </div>
              <div className='col col-12 col-md-4 col-lg-3 col-xl-2'>
              </div>
              <div className='col col-12 col-md-1 col-lg-1 text-center'>
                <button className='buttons' onClick={() => HandleModifier()}>Save</button>
              </div>
            </div>
            <div className='setting_inner row row-cols-1 row-cols-md-3 py-2 mb-3'>
              <div className='col col-12 col-md-2 col-lg-2 col-xl-1 heading'>
                <h5>Exclude Adjusted Campaigns</h5>
              </div>
              <div className='col col-12 col-md-4 col-lg-3 col-xl-2'>
                <div className=" form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isActive"
                    onChange={(event) => setAdjustStatus(event.target.checked)}
                    checked={adjustStatus}
                    value={adjustStatus}
                    style={{ width: "50px", height: "20px" }}
                  />
                </div>
              </div>
              <div className='col col-12 col-md-4 col-lg-3 col-xl-2'>
              </div>
              <div className='col col-12 col-md-1 col-lg-1 text-center'>
                <button className='buttons' onClick={() => statusHandler()}>Save</button>
              </div>
            </div>
            <div className='setting-inner borders mb-3'>
              <div className='setting_inner row row-cols-12 row-cols-md-3 py-2 border-0'>
                <div className='col col-5 col-md-2 col-lg-2 col-xl-1 heading'>
                  <h5>Set Average Order Value</h5>
                </div>
                <div className='col col-0 col-md-4 col-lg-3 col-xl-2'>
                </div>
                <div className='col col-0 col-md-3 col-lg-3 col-xl-2'>
                </div>
                <Modal show={addModalFlag} onHide={() => setaddModalFlag(false)} centered size="md" className='add_modal'>
                  <Modal.Header className="row campaign_modal_head ">
                    <div className="col-10 modal_title_box text-center">
                      <p className='filter_modal_title'>Average Order Value</p>
                    </div>
                    <div className="col-1 text-end">
                      <i
                        className="fa fa-times red modal_close_box py-3"
                        aria-hidden="true"
                        onClick={() => setaddModalFlag(false)}
                      ></i>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <AOVModal
                      setaddModalFlag={setaddModalFlag}
                      editId={editId}
                      flag={flag}
                      profileId={localStorage.getItem("profileId") || ""}
                      getAOV={getAOV}
                      setgetAOVlist={setgetAOVlist}
                      addAov={addAov}
                    />
                  </Modal.Body>
                </Modal>
                <div className='col col-5 col-md-2 col-lg-1 text-center'>
                  <button className='buttons px-1  ms-0 ms-auto ms-lg-0 px-2 ms-0 ms-md-auto ms-lg-0' onClick={() => { setaddModalFlag(true); setAddAov(true) }}>Add AOV</button>
                </div>
              </div>
              <div className='px-2 pb-2'>
                <DataTable
                  columns={columns}
                  data={getAOV}
                  striped={true}
                  style={{ tableLayout: 'auto' }}
                />
              </div>
            </div>

            <div className="setting_inner row row-cols-1 row-cols-md-3 py-2 mb-3 acos_filter limit_other_filter">
              <div className='col-12 heading'>
                <h5>Set Limit Value %</h5>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Increase Bid</th>
                    <th>Decrease Bid</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    limitArr?.map(el => {
                      return (
                        <tr>
                          <td><b>{el.fieldName} </b></td>
                          <td><input type='number' data-name={el.increaseBid} value={el.increaseBid} onChange={(e) => changeHandler(e, el, "increaseBid")} /></td>
                          <td><input type='number' data-name={el.decreaseBid} value={el.decreaseBid} onChange={(e) => changeHandler(e, el, "decreaseBid")} /></td>
                          <td className='save_btn'><button onClick={() => limitSaveHandler()}>Save</button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Other