import React, { useEffect, useState, useCallback } from 'react';
import Filter from '../../../helper/filter/Filter';
import { Modal } from 'react-bootstrap';
import FilterModal from './FilterModal';
import { GrEdit } from 'react-icons/gr';
import { AiOutlineDelete, AiTwotoneFileImage } from 'react-icons/ai';
import DataTable from 'react-data-table-component';
import { ApideleteRule } from '../../../api-wrapper/campaign/ApiFilter';
import { handleLoader } from '../../../redux/action';
import Toast from '../../../helper/toast/Toast';
import { useDispatch } from 'react-redux';
import { conditionOperators } from '../../../utility/constant';
import Button from 'react-bootstrap/Button';
import { ApiFilterList } from '../../../api-wrapper/campaign/ApiCampaignName';

// Constants
const MODAL_TYPES = {
  ADD: 'add',
  DELETE: 'delete',
};

// Utility Functions
const getOperatorDisplay = (operatorKey) =>
  conditionOperators?.find((op) => op.key === operatorKey)?.value || '-';

const ConditionDisplay = ({ conditions }) => (
  <div className='filter_type'>
    {conditions.map((el, index) => (
      <div key={index}>
        {index !== 0 && 'And'} {el.fieldName} {getOperatorDisplay(el.fieldOperator)} {el.fieldValue}
      </div>
    ))}
  </div>
);

const SortDisplay = ({ sorting }) =>
  sorting?.sortType
    ? `${sorting.sortName} ${sorting.sortType === 'ASCE' ? 'Ascending' : 'Descending'}`
    : '-';

const AddFilter = () => {
  const [filterData, setFilterData] = useState([]);
  const [modalState, setModalState] = useState({ type: null, data: null });
  const dispatch = useDispatch();

  const fetchFilters = useCallback(async () => {
    try {
      const profileId = localStorage.getItem('profileId') || '';
      dispatch(handleLoader(true));
      const response = await ApiFilterList({ pageNo: 1, perPage: 10, profileId });
      dispatch(handleLoader(false));

      if (response.isSuccess) {
        setFilterData(response.data);
      } else {
        Toast.error(response.message);
      }
    } catch (error) {
      dispatch(handleLoader(false));
      Toast.error('Something went wrong while fetching filters');
    }
  }, [dispatch]);

  const deleteFilter = async (id) => {
    try {
      dispatch(handleLoader(true));
      const response = await ApideleteRule(id);
      dispatch(handleLoader(false));

      if (response.isSuccess) {
        Toast.success(response.message);
        fetchFilters();
      } else {
        Toast.error(response.message);
      }
    } catch (error) {
      dispatch(handleLoader(false));
      Toast.error('Something went wrong while deleting the filter');
    }
  };

  const handleModalClose = () => setModalState({ type: null, data: null });

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const columns = [
    {
      name: 'Filter Name',
      selector: (row) => row.filterName,
      sortable: true,
    },
    {
      name: 'Days',
      selector: (row) => row.days,
      sortable: true,
    },
    {
      name: 'Conditions',
      cell: (row) => <ConditionDisplay conditions={row.conditions || []} />,
      width: '230px',
    },
    {
      name: 'Sort By',
      cell: (row) => <SortDisplay sorting={row.sorting} />,
    },
    {
      name: 'Action',
      width: '250px',
      cell: (row) => (
        <div className='d-flex align-items-center'>
          <GrEdit
            size={18}
            color='green'
            style={{ cursor: 'pointer' }}
            className='me-2'
            onClick={() => setModalState({ type: MODAL_TYPES.ADD, data: row })}
          />
          <AiOutlineDelete
            size={20}
            color='red'
            style={{ cursor: 'pointer' }}
            className='me-2'
            onClick={() => setModalState({ type: MODAL_TYPES.DELETE, data: row._id })}
          />
          {/* <AiTwotoneFileImage className='me-2' size={20} style={{ cursor: 'pointer' }} /> */}
        </div>
      ),
    },
  ];

  return (
    <div className='addfilter_container'>
      <div className='bg-white'>
        <Filter />
      </div>

      <div className='middle_container'>
        <div className='data_content data_content_btn'>
          <div className='data_modal_btn'>
            <h5>Filter</h5>
            <button
              className='modal_btn px-3'
              onClick={() => setModalState({ type: MODAL_TYPES.ADD, data: null })}
            >
              <i className='fa fa-plus pe-3'></i>Add Filter
            </button>
          </div>

          <DataTable striped={true} data={filterData} columns={columns} />
        </div>

        {/* Add/Edit Modal */}
        {modalState.type === MODAL_TYPES.ADD && (
          <Modal
            show={true}
            onHide={handleModalClose}
            centered
            size='xl'
            className='add_modal'
          >
            <Modal.Header className='campaign_modal_head'>
              <div className='col-11 modal_title_box text-center'>
                <p className='filter_modal_title'>Filter</p>
              </div>
              <div className='col-1 text-end'>
                <i
                  className='fa fa-times red modal_close_box py-3'
                  onClick={handleModalClose}
                ></i>
              </div>
            </Modal.Header>
            <Modal.Body>
              <FilterModal
                setModalState={setModalState}
                fetchFilters={fetchFilters}
                editData={modalState.data}
              />
            </Modal.Body>
          </Modal>
        )}

        {/* Delete Confirmation Modal */}
        {modalState.type === MODAL_TYPES.DELETE && (
          <Modal show={true} onHide={handleModalClose} className='delete_modal'>
            <Modal.Header closeButton>
              <h5>Filters</h5>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-center'>
                Are you sure you want to delete this record?
              </h5>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleModalClose}>
                Close
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteFilter(modalState.data);
                  handleModalClose();
                }}
              >
                Remove
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default AddFilter;
