import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import Filter from "../../../../helper/filter/Filter";
import Toast from "../../../../helper/toast/Toast";
import { handleLoader } from "../../../../redux/action";
import {
  ApiPlacementListByCampaign,
  ApiStore,
} from "../../../../api-wrapper/advertising/ApiAdvertising";
import ChildCampaign from "../campaigns/ChildCampaign";

const CampaignsAutomation = () => {
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(localStorage.getItem("fromDate") || "");
  const [toDate, setToDate] = useState(localStorage.getItem("toDate") || "");
  const [campaignData, setCampaignData] = useState([]);
  const [subval, setSubval] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);

  const columns = [
    {
      name: "Target Acos",
      sortable: true,
      selector: (row) => row.targetAcos,
      cell: () => <span>-</span>,
    },
    {
      name: "Target CPC",
      sortable: true,
      selector: (row) => row.targetCPC,
      cell: () => <span>-</span>,
    },
    {
      name: "Campaigns",
      selector: (row) => row.campaignName,
      width: "250px",
      sortable: true,
    },
    {
      name: "Bid Adjustment",
      sortable: true,
      selector: (row) => row.keywordBid,
      cell: (e) => `$${e.keywordBid}`,
    },
    {
      name: "New Bid Adjustment",
      selector: (row) => row.newbidAdjustment,
      sortable: true,
      cell: (e) => {
        const match = subval.find((x) => x.id === e.campaignId);
        return `$${match?.val ?? 0}`;
      },
    },
    { name: "Impressions", selector: (row) => row.totalImpressions, sortable: true },
    { name: "Clicks", selector: (row) => row.totalClicks, sortable: true },
    {
      name: "CTR%",
      sortable: true,
      selector: (row) => row.totalCtr,
      cell: (e) => `${e.totalCtr}%`,
    },
    { name: "Spend", sortable: true, selector: (row) => row.totalSpend, cell: (e) => `$${e.totalSpend}` },
    { name: "Sales", sortable: true, selector: (row) => row.totalSales, cell: (e) => `$${e.totalSales}` },
    { name: "Orders", selector: (row) => row.totalOrders, sortable: true },
    { name: "Units", selector: (row) => row.totalOrders, sortable: true },
    {
      name: "CVR%",
      sortable: true,
      selector: (row) => row.totalCvr,
      cell: (e) => `${e.totalCvr}%`,
    },
    {
      name: "ACOS%",
      sortable: true,
      selector: (row) => row.totalAcos,
      cell: (e) => `${e.totalAcos}%`,
    },
    {
      name: "CPC",
      sortable: true,
      selector: (row) => row.totalCpc,
      cell: (e) => `$${e.totalCpc}`,
    },
    { name: "ROAS", selector: (row) => row.totalRoas, sortable: true },
    { name: "Sponsored Rank", selector: () => "-", sortable: true, width: "40px" },
    { name: "Organic Rank", selector: () => "-", sortable: true, width: "40px" },
  ];

  const dateHandler = (e) => {
    if (e) {
      setFromDate(e[0]);
      setToDate(e[1]);
    }
  };

  const getCampaignList = useCallback(async () => {
    const profileId = localStorage.getItem("profileId") || "";
    if (fromDate && toDate) {
      dispatch(handleLoader(true));
      const data = {
        isManage: false,
        profileId,
        fromDate,
        toDate,
        pageNo: 1,
        perPage: 2500,
        type: 2,
        filterIds: [],
        searchType: "",
        search: "",
      };

      try {
        const response = await ApiPlacementListByCampaign(data);
        if (response?.isSuccess) {
          setCampaignData(response.placementsData);
          setSubval([]);
        } else {
          Toast.error(response?.message);
        }
      } catch {
        Toast.error("Something went wrong");
      } finally {
        dispatch(handleLoader(false));
      }
    }
  }, [fromDate, toDate, dispatch]);

  useEffect(() => {
    getCampaignList();
  }, [getCampaignList]);

  useEffect(() => {
    localStorage.setItem("fromDate", fromDate);
    localStorage.setItem("toDate", toDate);
  }, [fromDate, toDate]);

  const handleRowExpandToggle = (expanded, row) => {
    if (expanded) {
      setExpandedRows([...expandedRows, row]);
      setCampaignIds([...campaignIds, row.campaignId]);
    } else {
      setExpandedRows(expandedRows.filter((id) => id.campaignId !== row.campaignId));
      setCampaignIds(campaignIds.filter((id) => id !== row.campaignId));
    }
  };

  const isRowExpanded = (row) => expandedRows.includes(row.campaignId);

  const customExpandIcon = {
    collapsed: <AiOutlinePlus />,
    expanded: <AiOutlineMinus />,
  };

  const ExpandedComponent = useCallback(
    ({ data }) => (
      <div className="sub_table campaign_table campaign_sub" key={data.campaignId}>
        <ChildCampaign placementData={data?.placementsData} setsubval={setSubval} subval={subval} />
      </div>
    ),
    [subval]
  );

  const handleApply = async (name) => {
    const profileId = localStorage.getItem("profileId");
    const campaignsend = campaignData[0];

    const data = {
      campaignId: campaignsend.campaignId,
      profileId: profileId || "",
      newKeywordBid: subval[0]?.val || 0,
      timezone: localStorage.getItem("antonTimezone"),
      TOS_Acos: campaignsend.placementsData[0]?.targetAcos,
      TOS_Cpc: campaignsend.placementsData[0]?.targetCPC,
      TOS_modifire: campaignsend.placementsData[0]?.newbidAdjustment,
      PP_Acos: campaignsend.placementsData[1]?.targetAcos,
      PP_Cpc: campaignsend.placementsData[1]?.targetCPC,
      PP_modifire: campaignsend.placementsData[1]?.newbidAdjustment,
      ROS_Acos: campaignsend.placementsData[2]?.targetAcos,
      ROS_Cpc: campaignsend.placementsData[2]?.targetCPC,
      applyOn: name,
    };

    try {
      const response = await ApiStore(data);
      if (response.isSuccess) {
        Toast.success(response.message);
        getCampaignList();
      } else {
        Toast.error(response.message);
      }
    } catch {
      Toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <div className="bg-white">
        <Filter dateHandler={dateHandler} fromDate={fromDate} toDate={toDate} />
      </div>
      <div className="middle_container heading_none">
        <div className="data_content">
          <div className="data_table campaign_table scrollable_table">
            <div className="campaign_filter_data campaign_btn campaign_filter ms-auto">
              <button className="expand-btn" onClick={() => handleApply("DB")}>
                Save In DB
              </button>
              <button className="expand-btn" onClick={() => handleApply("Amazon")}>
                Apply On Amazon
              </button>
            </div>
            <DataTable
              columns={columns}
              data={campaignData}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              expandableIcon={customExpandIcon}
              onRowExpandToggled={handleRowExpandToggle}
              expandableRowExpanded={isRowExpanded}
              keyField="campaignId"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignsAutomation;
