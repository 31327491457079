import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ApiCreate, ApiRuleConditions, ApiupdateRule } from '../../../api-wrapper/campaign/ApiFilter';
import { useDispatch } from 'react-redux';
import { handleLoader } from '../../../redux/action';
import Toast from '../../../helper/toast/Toast';
import { conditionType, serachLabel, sortList, validatorLabel } from '../../../utility/constant';
const Addfilter = ({ setaddModalFlag, editId, getRule }) => {
    const dispatch = useDispatch()
    const {
        register,
        reset,
    } = useForm({});
    const [searchType, setSearchType] = useState('Campaigns');
    const [days, setDays] = useState(0);
    const [mainRuleData, setMainRuleData] = useState([
        {
            searchType: "",
            fieldName: "",
            fieldOperator: "",
            fieldValue: "",
        },
    ]);
    const [sortBy, setSortBy] = useState(
        {
            sortName: "",
            sortType: "",
        },
    );
    const [error, seterror] = useState();
    const [ruleName, setRuleName] = useState("");

    const addConditionHandler = () => {
        setMainRuleData([
            ...mainRuleData,
            {
                searchType: "",
                fieldName: "",
                fieldOperator: "",
                fieldValue: "",
            },
        ]);
    };

    const mainRuleChangeHandler = (e, obj, address_type) => {
        if (address_type) {
            obj[address_type] = e.target.value;
        } else {
            obj[e.target.attributes["data-name"].value] = e.target.value;
        }

        setMainRuleData([...mainRuleData]);
    };

    const errorFun = (index, label, arrayName) => {

        let arr;

        if (arrayName == 'condition') {
            arr = error?.conditions[index];
        }
        if (arrayName == 'sort') {
            arr = error?.sorting[index];
        }
        return (
            <>
                {arr?.map((obj, i) => {
                    if (obj.hasOwnProperty(label)) {
                        return (
                            <p key={i} className="error">
                                {obj[label]}
                            </p>
                        );
                    }
                })}
            </>
        );
    };

    const handleRemoveRule = (index) => {
        const newData = [...mainRuleData];
        newData.splice(index, 1);
        setMainRuleData(newData);
    }

    //   ----------api call -----------------//
    const submitHandler = (e) => {
        const profileId = localStorage.getItem("profileId");
        e.preventDefault();

        let flag = false;
        let sendData = {
            days: days,
            profileId: profileId || '',
            filterName: ruleName,
            conditions: mainRuleData,
            sorting: sortBy,
            searchType: searchType
        };



        let errorData = {};
        if (sendData.filterName == "") {
            flag = true;
            errorData = {
                ...errorData,
                ruleName: "Filter Name is required",
            };
        }



        if (sendData.conditions) {
            let mainArr = [];
            sendData?.conditions?.map((el, index) => {
                let subArr = [];
                for (const property in el) {
                    if (el[property] === "") {
                        flag = true;
                        subArr.push({
                            [property]: `${property} is required`,
                        });
                        mainArr[index] = subArr;
                    }
                }
            });
            errorData = {
                ...errorData,
                conditions: mainArr,
            };
        }

        if (sendData.sorting?.sortName == '') {
            flag = true;
            errorData = {
                ...errorData,
                sortName: "sortName is required",
            };
        }

        if (sendData.sorting?.sortType == '') {
            flag = true;
            errorData = {
                ...errorData,
                sortType: "sortType is required",
            };
        }

        seterror(errorData);

        // API call //
        if (!flag) {
            dispatch(handleLoader(true));
            if (editId) {
                ApiupdateRule(editId, sendData)
                    .then((res) => {
                        if (res.isSuccess) {
                            getRule()
                            Toast.success(res.message);
                            dispatch(handleLoader(false));
                        } else {
                            Toast.error(res.message);
                            dispatch(handleLoader(false));
                        }
                        setaddModalFlag(false);
                    })
                    .catch((err) => {
                        Toast.error(err);
                        dispatch(handleLoader(false));
                        setaddModalFlag(false);
                    });
            } else {
                ApiCreate(sendData)
                    .then((res) => {
                        if (res.isSuccess) {
                            getRule()
                            Toast.success(res.message);
                            dispatch(handleLoader(false));
                        } else {
                            Toast.error(res.message);
                            dispatch(handleLoader(false));
                        }
                        setaddModalFlag(false);
                    })
                    .catch((err) => {
                        Toast.error(err);
                        dispatch(handleLoader(false));
                        setaddModalFlag(false);
                    });
            }
            seterror()
        }

    };
    // get rules by id  //
    useEffect(() => {

        if (editId) {
            dispatch(handleLoader(true));
            ApiRuleConditions(editId)
                .then((res) => {
                    if (res.isSuccess) {

                        setRuleName(res.data.filterName);
                        reset(res.data);
                        setMainRuleData(res.data?.conditions);
                        setSortBy(res.data?.sorting)
                        setSearchType(res.data?.searchType)

                        setDays(res.data.days)
                        dispatch(handleLoader(false));
                    } else {
                        Toast.error(res.message);
                        dispatch(handleLoader(false));
                    }
                })
                .catch((err) => {
                    dispatch(handleLoader(false));
                    Toast.error(err);
                });
        }

    }, []);

    const handleActionType = e => {

        setSortBy({
            ...sortBy,
            [e.target.name]: e.target.value
        })


    }


    const changeHandler = (e) => {

        let day = Number(e.target.value)
        setDays(day)
    }

    return (
        <>
            <form >

                <div className='add_filter'>
                    <label className='my-2'>No. Of Days :</label>
                    <div className='row form_group dotted_border pb-3 align-center'>
                        <div className='col-lg-12'>

                            <input type='text' name="days" value={days} onChange={(e) => changeHandler(e)} />

                        </div>

                    </div>

                    <div className='col-lg-12 form_group dotted_border pb-1'>
                        <label className='my-2'>Filter Name :</label>
                        <div className='p-2 px-3'>
                            <input type='text' placeholder='Enter filter Name'
                                {...register(`ruleName`)}
                                onChange={(e) => setRuleName(e.target.value)}
                                value={ruleName}
                            />
                            <p className="error">{error?.ruleName && error.ruleName}</p>
                        </div>
                    </div>



                    <div className=' dotted_border'>
                        <div className='form_group col-12 plus_filter d-flex align-items-center justify-content-between my-2 '>
                            <label className='pb-0'>Conditions : </label>
                            <button className='add_sub_filter' onClick={() => addConditionHandler()} type='button'>
                                <i class="fa fa-plus" ></i>
                            </button>
                        </div>


                        {
                            mainRuleData?.map((element, index) => {
                                return (
                                    <div className="row mb-2 main_row">
                                        <div className="" >
                                            <select
                                                className="form_rule"
                                                name="searchType"
                                                {...register(`main.${index}.searchType`, {
                                                    onChange: (e) =>
                                                        mainRuleChangeHandler(e, element, "searchType"),
                                                })}
                                                value={element.searchType}
                                            >
                                                <option hidden>Select</option>
                                                {serachLabel.map((el, i) => (
                                                    <option value={el.value} key={i}>
                                                        {el.label}
                                                    </option>
                                                ))}
                                            </select>

                                            {errorFun(index, "searchType", "condition")}
                                        </div>
                                        <div className="" >
                                            <select
                                                className="form_rule"
                                                name="fieldName"
                                                {...register(`main.${index}.fieldName`, {
                                                    onChange: (e) =>
                                                        mainRuleChangeHandler(e, element, "fieldName"),
                                                })}
                                                value={element.fieldName}
                                            >
                                                <option hidden>Select</option>
                                                {conditionType.map((el, i) => (
                                                    <option value={el.value} key={i}>
                                                        {el.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFun(index, "fieldName", "condition")}
                                        </div>
                                        <div className="" >
                                            <select
                                                name="fieldOperator"
                                                className="form_rule"
                                                {...register(`main.${index}.fieldOperator`, {
                                                    onChange: (e) =>
                                                        mainRuleChangeHandler(
                                                            e,
                                                            element,
                                                            "fieldOperator"
                                                        ),
                                                })}
                                                value={element.fieldOperator}
                                            >
                                                <option hidden>Select</option>
                                                {validatorLabel.map((el, i) => (
                                                    <option key={i} value={el.value}>
                                                        {el.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFun(index, "fieldOperator", "condition")}
                                        </div>
                                        <div className="" >
                                            <input
                                                type="number"
                                                data-name="fieldValue"
                                                className="form_rule"
                                                placeholder="Value"
                                                {...register(`mainRuleData.${index}.fieldValue`, {
                                                    onChange: (e) => mainRuleChangeHandler(e, element),
                                                })}
                                                value={element.fieldValue}
                                            />
                                            {errorFun(index, "fieldValue", "condition")}
                                        </div>
                                        <div className="minus_icon" >
                                            {
                                                index != 0 &&
                                                <i class="fa fa-trash" onClick={() => handleRemoveRule(index)}></i>

                                            }
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                    <div className=' dotted_border'>
                        <div className='form_group col-12 plus_filter d-flex align-items-center justify-content-between my-2'>
                            <label className='pb-0'>Sort By : </label>
                        </div>
                        <div className="row mb-2 main_row">
                            <div className="" >
                                <select
                                    className="form_rule"
                                    name="sortName"
                                    value={sortBy?.sortName}
                                    onChange={(e) => handleActionType(e)}
                                >
                                    <option hidden>Select</option>
                                    {conditionType.map((el, i) => (
                                        <option value={el.value} key={i}>
                                            {el.label}
                                        </option>
                                    ))}
                                </select>
                                <p className="error">{error?.sortName && error.sortName}</p>
                            </div>
                            <div className="" >
                                <select
                                    name="sortType"
                                    className="form_rule"
                                    value={sortBy?.sortType}
                                    onChange={(e) => handleActionType(e)}
                                >
                                    <option hidden>Select</option>
                                    {sortList.map((el, i) => (
                                        <option key={i} value={el.value}>
                                            {el.label}
                                        </option>
                                    ))}
                                </select>
                                <p className="error">{error?.sortType && error.sortType}</p>
                            </div>
                        </div>
                    </div>
                    <div className='form_btn mt-3 text-center'>
                        <button className="cancel_btn" type='button' onClick={() => setaddModalFlag(false)}>  Cancel </button>
                        <button className="  btn btn-primary" type="submit" onClick={(e) => submitHandler(e)} >Submit</button>
                    </div>

                </div>
            </form >
        </>
    )
}
export default Addfilter