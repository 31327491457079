import React, { useState } from 'react'
import Filter from '../../../helper/filter/Filter'
import CampaignTable from './CampaignTable'
import { useEffect } from 'react';

const Index = () => {
    const [campaignId, setcampaignId] = useState("")
    const [fromDate, setfromDate] = useState(localStorage.getItem("fromDate") || '');
    const [toDate, settoDate] = useState(localStorage.getItem("toDate") || '');
    const campaignIdHandler = (e) => {
        setcampaignId(e)
    }

    const dateHandler = (e) => {
        if (e) {
            setfromDate(e[0]);
            settoDate(e[1]);
        }
    }

    useEffect(() => {
        localStorage.setItem('fromDate', fromDate);
        localStorage.setItem('toDate', toDate);
    }, [fromDate, toDate])
    return (
        <>
            <div className='bg-white'>
                <Filter order={true} campaignIdHandler={campaignIdHandler} dateHandler={dateHandler} fromDate={fromDate} toDate={toDate} dateShow={true} />
            </div>
            <div className="middle_container sb_container">
                <CampaignTable campaignId={campaignId} fromDate={fromDate} toDate={toDate} />
            </div>
        </>
    )
}

export default Index