import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Filter from "../../../../helper/filter/Filter";
import Toast from "../../../../helper/toast/Toast";
import { ApiPlacementListMange, ApiUnmange } from "../../../../api-wrapper/campaign/ApiCampaignName";
const UnmanageCampaign = () => {

    const [totalRecords, settotalRecords] = useState(0);
    const [arg, setArg] = useState({
        pageNo: 1,
        perPage: 10,
    });
    const [filter, setFilter] = useState("Sponsored Products");
    const [campaignData, setcampaignData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = [
        {
            name: "Campaigns",
            selector: (row) => row.campaignName,
            width: "450px",
            sortable: true,
        },
    ]

    const getCampaignData = async () => {
        const profileId = localStorage.getItem("profileId");
        if (profileId) {

            let data = {
                profileId: profileId,
                type: filter,
                ...arg
            };

            try {
                const e = await ApiPlacementListMange(data);
                if (e?.isSuccess) {
                    settotalRecords(e.totalRecords);
                    setcampaignData(e.unmanageCampaign);

                } else {
                    Toast.error(e?.message);
                }
            } catch (error) {
                Toast.error("Something went wrong");
            } finally {

            }
        }
    };

    useEffect(() => {
        getCampaignData()
    }, [arg, filter]);

    const HandleUnMange = () => {
        let filterData = selectedRows.selectedRows?.map(el => el.campaignId)
        const profileId = localStorage.getItem("profileId");
        let data = {
            isManage: true,
            type: filter,
            campaignIds: filterData,
            profileId: profileId,
        };

        ApiUnmange(data)
            .then((res) => {
                if (res.isSuccess) {
                    Toast.success(res.message);
                    getCampaignData()
                    setSelectedRows([])
                }
            }).catch((err) => {
                Toast.error("somthing went wrong!!");
            });
    }

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <div className="form-check" style={{ backgroundColor: '' }}>
                    <input
                        type="checkbox"
                        className="form-check-input"

                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <label className="form-check-label" id="booty-check" />
                </div>
            </>
        )
    })

    const handleSelectedRowsChange = (newSelectedRows) => {
        setSelectedRows(newSelectedRows);
    };

    return (
        <div className="bg-white">
            <Filter
                dateShow={false}
            />

            <div className="middle_container heading_none">
                <div className="data_content ">
                    <div>
                        <button className="blue_btn m-2 float-end" onClick={() => HandleUnMange()}>
                            UnManaged Campaigns
                        </button>
                    </div>
                    <div className="history-filter" >

                        <select
                            name="sortType"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <option value={"Sponsored Products"}>  Sponsored Products  </option>
                            <option value={"Sponsored Brands"}>  Sponsored Brands  </option>
                            <option value={"Sponsored Display"}>  Sponsored Display </option>
                        </select>
                    </div>
                    <DataTable
                        className="table_content"
                        columns={columns}
                        data={campaignData}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handleSelectedRowsChange}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        onChangePage={(page) => setArg({ pageNo: page, perPage: arg.perPage })}
                        onChangeRowsPerPage={(newPerPage, page) => setArg({ pageNo: page, perPage: newPerPage })}
                        paginationTotalRows={totalRecords}
                    />
                </div>
            </div>
        </div>
    )
}

export default UnmanageCampaign