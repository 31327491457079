import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

const ChildCampaign = ({ placementData, setsubval, subval }) => {

    const [subData, setSubData] = useState([]);

    const childColumn = [
        {
            name: "Target Acos",
            selector: (row) => row,
            cell: (e, rowIndex) => {

                const changeEvent = ($event) => {
                    $event.preventDefault();
                    let eventValue = Number($event.target.value)
                    let crrObj = subData;
                    let acosVal = eventValue;
                    let TotalSales = e.sales;
                    let TotalClick = e.clicks;
                    let result = (TotalSales / TotalClick) * (acosVal / 100);
                    let finalVal = result.toFixed(2)
                    crrObj[rowIndex].targetAcos = eventValue == '' ? 0 : eventValue;
                    crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) > 0.45 ? Number(finalVal) : 0.45 : 0;

                    let placement = crrObj;
                    let minIdIndex = 0;
                    let totalSales = 0;
                    let totalClicks = 0;
                    let divide = 0
                    let resule = 0

                    let highestIndex = 0;

                    for (let i = 1; i < placement.length; i++) {
                        if (placement[i].targetCPC > placement[highestIndex].targetCPC) {
                            highestIndex = i;
                        }
                    }

                    let minIdValue = placement[0].targetCPC;

                    for (let i = 1; i < placement.length; i++) {
                        if (placement[i].targetCPC < minIdValue) {
                            minIdIndex = i;
                            minIdValue = placement[i].targetCPC;
                        }
                    }

                    if (placement[0].targetAcos != undefined && placement[1].targetAcos != undefined && placement[2].targetAcos != undefined) {


                        for (let i = 0; i < placement.length; i++) {
                            if (i >= minIdIndex) {
                                totalSales = Number(totalSales) + Number(placement[i].sales)
                                totalClicks = Number(totalClicks) + Number(placement[i].clicks)
                            }
                        }

                        if (highestIndex == 1) {
                            let totalSales = placement[0].sales + placement[2].sales;
                            let totalClicks = placement[0].clicks + placement[2].clicks;

                            divide = (totalSales / totalClicks) * (Number(placement[2]?.targetAcos) / 100)
                            resule = (divide) ? divide.toFixed(2) : 0
                        }
                        else {
                            divide = (totalSales / totalClicks) * (Number(placement[2]?.targetAcos) / 100)
                            resule = (divide) ? divide.toFixed(2) : 0
                        }
                        // --- child bid calculation ----//
                        let targetCPC0 = 0
                        let targetCPC1 = 0
                        let adjustment0 = 0
                        let adjustment1 = 0

                        if (resule) {

                            targetCPC0 = (subData[0].targetCPC / resule) - 1
                            targetCPC1 = (subData[1].targetCPC / resule) - 1
                            adjustment0 = (targetCPC0 * 100).toFixed(2)
                            adjustment1 = (targetCPC1 * 100).toFixed(2)
                            subData[0].newbidAdjustment = adjustment0 < 0 ? 0 : adjustment0 > 900 ? 900 : adjustment0
                            subData[1].newbidAdjustment = adjustment1 < 0 ? 0 : adjustment1 > 900 ? 900 : adjustment1

                        }
                        else {
                            subData[0].newbidAdjustment = 0
                            subData[1].newbidAdjustment = 0
                        }

                    }

                    let updatedSubval = subval;
                    let existingIndex = updatedSubval.findIndex(ee => ee.id === e.campaignId);
                    if (existingIndex !== -1) {
                        updatedSubval[existingIndex].val = resule == NaN || resule == undefined ? 0 : resule
                    } else {
                        updatedSubval.push({
                            id: e.campaignId,
                            val: resule
                        });
                    }

                    setsubval([...updatedSubval]);
                }

                return (
                    <>
                        <input onChange={changeEvent} value={e.targetAcos} type="text" className="cal" />
                    </>
                );
            },
        },
        {
            name: "Target CPC",
            sortable: true,
            selector: (row) => row.targetCPC ?? "-",
            cell: (e) => {
                return `$${e.targetCPC == undefined ? 0 : e.targetCPC}`;
            },
        },
        {
            name: "placement",
            selector: (row) => row.placement,
            cell: (e) => {
                return <div className="">{e.placement}</div>;
            },
        },
        {
            name: "Bid Adjustment",
            selector: (row) => row.placementModifier ?? "-",
            cell: (e) => {
                return `${e.placementModifier}%`;
            },
        },
        {
            name: "New Bid Adjustment",
            selector: (row) => row.newbidAdjustment,
            cell: (e) => {
                if (e.sales == 0) {
                    if (e.placementModifier) {
                        return `${e.placementModifier}%`;
                    } else {
                        return "0%";
                    }
                } else {
                    if (
                        e.newbidAdjustment == undefined ||
                        e.newbidAdjustment == "undefined"
                    ) {
                        return "0%";
                    } else {
                        return `${e.newbidAdjustment}%`;
                    }
                }
            },
        },
        {
            name: "Impressions",
            selector: (row) => row.impressions,

        },
        {
            name: "Clicks",
            selector: (row) => row.clicks,

        },
        {
            name: "CTR%",
            selector: (row) => row.ctr,
            cell: (e) => `${e.ctr}%`,

        },
        {
            name: "Spend",
            selector: (row) => row.spend,
            cell: (e) => `$${e.spend}`,

        },
        {
            name: "Sales",
            selector: (row) => row.sales,
            cell: (e) => `$${e.sales}`,

        },
        {
            name: "Orders",
            selector: (row) => row.orders,

        },
        {
            name: "Units",
            selector: (row) => row.orders,

        },

        {
            name: "CVR%",
            selector: (row) => row.cvr,
            cell: (e) => `${e.cvr}%`,

        },
        {
            name: "ACOS%",
            selector: (row) => row.acos,
            cell: (e) => `${e.acos}%`,

        },
        {
            name: "CPC",
            selector: (row) => row.cpc,
            cell: (e) => `$${e.cpc}`,

        },

        {
            name: "ROAS",
            selector: (row) => row.roas,

        },
        {
            name: "Sponsored Rank",
            selector: (row) => "-",
            width: "40px",
        },
        {
            name: "Organic Rank ",
            selector: (row) => "-",
            width: "40px",
        },
    ];

    useEffect(() => {
        setSubData(placementData);
    }, [placementData]);

    const cal = () => {

        if (placementData?.length != 0 && placementData != undefined) {

            let crrObj = placementData;
            crrObj?.map((e, rowIndex) => {
                let eventValue = e.targetAcos;
                let TotalSales = e.sales;
                let TotalClick = e.clicks;
                let result = (TotalSales / TotalClick) * (eventValue / 100);
                let finalVal = result.toFixed(2)
                crrObj[rowIndex].targetAcos = eventValue == '' ? 0 : eventValue;
                crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) > 0.45 ? Number(finalVal) : 0.45 : 0;

            })
            //  ---------cpc(new bid adjustment) calculation--------- //

            let placement = crrObj;
            let minIdIndex = 0;
            let totalSales = 0;
            let totalClicks = 0;
            let divide = 0
            let resule = 0

            //max cpc index find //
            let highestIndex = 0;

            for (let i = 1; i < placement.length; i++) {
                if (placement[i].targetCPC > placement[highestIndex].targetCPC) {
                    highestIndex = i;
                }
            }

            //  min cpc index find//
            let minIdValue = placement[0].targetCPC;

            for (let i = 1; i < placement.length; i++) {
                if (placement[i].targetCPC < minIdValue) {
                    minIdIndex = i;
                    minIdValue = placement[i].targetCPC;
                }
            }

            if (placement[0].targetAcos != undefined && placement[1].targetAcos != undefined && placement[2].targetAcos != undefined) {

                for (let i = 0; i < placement.length; i++) {
                    if (i >= minIdIndex) {
                        totalSales = Number(totalSales) + Number(placement[i].sales)
                        totalClicks = Number(totalClicks) + Number(placement[i].clicks)
                    }
                }

                if (highestIndex == 1) {
                    let totalSales = placement[0].sales + placement[2].sales;
                    let totalClicks = placement[0].clicks + placement[2].clicks;

                    divide = (totalSales / totalClicks) * (Number(placement[2]?.targetAcos) / 100)
                    resule = (divide) ? divide.toFixed(2) : 0
                }
                else {
                    divide = (totalSales / totalClicks) * (Number(placement[2]?.targetAcos) / 100)
                    resule = (divide) ? divide.toFixed(2) : 0
                }
                // --- child bid calculation ----//
                let targetCPC0 = 0
                let targetCPC1 = 0
                let adjustment0 = 0
                let adjustment1 = 0

                if (resule) {

                    targetCPC0 = (placementData[0].targetCPC / resule) - 1
                    targetCPC1 = (placementData[1].targetCPC / resule) - 1
                    adjustment0 = (targetCPC0 * 100).toFixed(2)
                    adjustment1 = (targetCPC1 * 100).toFixed(2)
                    placementData[0].newbidAdjustment = adjustment0 < 0 ? 0 : adjustment0 > 900 ? 900 : adjustment0
                    placementData[1].newbidAdjustment = adjustment1 < 0 ? 0 : adjustment1 > 900 ? 900 : adjustment1

                }
                else {
                    placementData[0].newbidAdjustment = 0
                    placementData[1].newbidAdjustment = 0
                }

            }

            let updatedSubval = subval;

            placementData.map(a => {
                let existingIndex = updatedSubval?.findIndex(ee => ee.id === a.campaignId);
                if (existingIndex !== -1) {
                    updatedSubval[existingIndex].val = resule == NaN || resule == undefined ? 0 : resule
                } else {
                    updatedSubval.push({
                        id: a.campaignId,
                        val: resule
                    });
                }
            })

            setsubval([...updatedSubval]);
        }
    }

    useEffect(() => {
        cal(placementData)
    }, [placementData]);

    return (
        <>
            <DataTable
                className="table_content"
                columns={childColumn}
                data={subData}
                fixedHeader
            />
        </>
    )
}

export default ChildCampaign